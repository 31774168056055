/**
 * @file
 *
 * XS media query styles.
 */

@include breakpoint($xs-media) {
  // Your styles here.
  #header {
    .main {
      .mobile-only {
        @include susy-clearfix;

        .telephone-and-worktime {
          @include span(6);
        }

        .email-and-address {
        }
      }
    }
  }

  .view-nodes-footer-portfolio {
    .content {
      @include susy-clearfix;

      .item {
        @include gallery(6);
      }
    }
  }
}
