/**
 * @file
 *
 * SM media query styles.
 */

@include breakpoint($sm-media) {
  $site-width: 768px;
  #app {
    @include container($site-width);
  }

  #header {
    .main {
      @include susy-clearfix;

      .logo {
        @include span(2);
      }

      .telephone-and-worktime {
        @include span(4);
      }

      .last {
        @include span(6 last);

        .bottom {

          .email-and-address {
          }

          .right {
          }
        }
      }
    }
  }

  #superfish-1 {
    @include flexbox;
    @include flex-flow();
    @include justify-content();
    > li {
      @include flex(1, 1, auto);
    }
  }

  .article-teaser {
    @include susy-clearfix;

    .promo {
      @include span(4);
    }

    .content {
      @include span(8 last);
    }
  }


  .news-teaser {
    @include susy-clearfix;

    .promo {
      @include span(4);
    }

    .content {
      @include span(8 last);
    }

    &.no-promo {
      .content {
        @include full();
      }
    }
  }

  #main {
    @include susy-clearfix;
  }

  #footer {
    .icons {
      @include susy-clearfix;
      .item {
        @include span(4);

        &:last-child {
          @include last();
        }
      }
    }

    .blocks {
      @include susy-clearfix;

      .block {
        @include gallery(4);
      }
    }
  }

  .view-nodes-services {
    .content {
      @include susy-clearfix;

      .service-teaser {
        @include gallery(6);
      }
    }
  }

  .service-full {
    .variants {
      @include flexbox;
      @include flex-wrap(space-between);
      @include flex-flow(row wrap);
      @include justify-content(center);
      .variant {
      }
    }

    .services {
      .items {
        @include susy-clearfix;

        .service-teaser {
          @include gallery(6);
        }
      }
    }
  }

  .paragraph-service-variants {
    @include flexbox;
    @include flex-wrap(space-between);
    @include flex-flow(row wrap);
    @include justify-content(center);
  }

  .view-node-search-index-projects,
  .view-node-search-index-category,
  .view-node-search-index-pane-projects-in-category {
    .content {
      @include susy-clearfix;

      .project-teaser {
        @include gallery(4);
      }
    }
  }

  .project-full {
    > .head {
      @include susy-clearfix;

      .left {
        @include span(8);
      }

      .right {
        @include span(4 last);

        .layout {
          .items {
            @include susy-clearfix;

            .item {
              @include gallery(6);
            }
          }
        }
      }
    }

    .complectation-and-description {
      @include susy-clearfix;

      .complectation {
      }

      .description {
      }
    }

    .similar-projects {
      .items {
        @include susy-clearfix;

        .project-teaser {
          @include gallery(6);
        }
      }
    }
  }

  #block-block-6 {
    .row {
      @include susy-clearfix;

      .label {
        @include span(2);
      }

      .items {
        @include span(10 last);

        .item {
        }
      }
    }
  }

  #block-block-10 {
    .items {
      @include susy-clearfix;

      .item {
        @include gallery(6);
      }
    }
  }

  #block-block-7 {
    .services {
      @include susy-clearfix;

      .service {
        @include gallery(4);
      }
    }
  }

  .view-nodes-popular {
    .content {
      @include susy-clearfix;

      .project-teaser {
        @include gallery(3);
      }
    }
  }

  #block-block-8 {
    .two-sides {
      @include susy-clearfix;

      .left {
        @include span(8);
        @include susy-clearfix;

        .item {
          @include gallery(6);
        }
      }

      .right {
        @include span(4 last);
      }
    }
  }

  .view-nodes-portfolio {
    .content {
      @include susy-clearfix;

      .portfolio-teaser {
        @include gallery(4);
      }
    }
  }

  .portfolio-full {
    > .content {
      lost-flex-container: row;

      > .left {
        lost-column: 8/12;
      }

      > .right {
        lost-column: 3/12;
      }
    }

    > .bottom {
      width: 100%;
    }

    .other-portfolio-items {
      lost-flex-container: row;

      .portfolio-teaser {
        lost-waffle: 3/12 4;
      }
    }

    .actual-project-items {
      lost-flex-container: row;

      .project-teaser {
        lost-waffle: 3/12 4;
      }
    }
  }

  .review-teaser {
    @include susy-clearfix;
    > .left {
      @include span(2);
    }
    > .right {
      @include span(10 last);
    }

    &.no-promo {
      > .right {
        @include full();
      }
    }
  }

  .entitytype-consultation_request-form {
    .row {
      @include susy-clearfix;

      .first {
        @include span(6);
      }

      .second {
        @include span(6 last);
      }
    }
  }

  #contact-entityform-edit-form {
    .pre-instructions {
      .first {
        @include susy-clearfix;
        .photo {
          float: right;
        }
      }
      .second {
        @include susy-clearfix;
        .scheme {
          @include span(8);
        }
        .route {
          @include span(4 last);
        }
      }
      .third {
        @include susy-clearfix;
        .ppl {
          @include span(8);
          .photo {
            float: right;
          }
        }
        .form-pane {
          @include span(4 last);
        }
      }
    }
  }

  .view-nodes-services-frontpage {
    .content {
      @include susy-clearfix;

      .service-teaser-second {
        @include gallery(4);
      }
    }
  }

  .view-nodes-footer-portfolio {
    .content {
      @include susy-clearfix;

      .item {
        @include gallery(6);
      }
    }
  }

  #block-block-11 {
    .two-sides {
      @include susy-clearfix;

      .first {
        @include span(6);
      }

      .second {
        @include span(6 last);
      }
    }
  }

  #block-block-12 {
    .menu-and-slider {
      @include susy-clearfix;

      .menu {
        @include span(4);
      }

      .view-brusmaster-slide-front {
        @include span(8 last);
      }
    }
  }

  @for $i from 1 through 40 {
    .view-project-page-with-fitlers-page-#{$i} {
      .header {
        margin-bottom: rem(15);
      }
    }
  }

  .view-nodes-contragents {
    > .content {
      @include susy-clearfix;

      .partner-teaser {
        @include gallery(6);
      }
    }
  }

  .partner-teaser {
    @include susy-clearfix;

    .logo {
      @include span(4);
    }

    .info {
      @include span(8 last);
    }
  }
}
