/**
 * @file
 */

$message-error-bg:     #F2DEDE;
$message-error-border: #EBCCD1;
$message-error-color:  #A94442;

$message-status-bg:     #DFF0D8;
$message-status-border: #D6E9C6;
$message-status-color:  #3C763D;

$message-warning-bg:     #FCF8E3;
$message-warning-border: #FAEBCC;
$message-warning-color:  #8A6D3B;
