/**
 * @file
 * Main styles. Here must be you stylesheet.
 * Use it well.
 */

@include border-box-sizing();

p:last-child {
  margin-bottom: 0;
}

#app {
  background: white;
  box-shadow: 0 0 100px gainsboro;

  .modal-open & {
    -webkit-filter: blur(7px);
    filter: blur(7px);
  }

  @include breakpoint($xs-media) {
    margin-top: 60px;
  }
}

#header {
  .main {
    padding: rem(15) rem(20);

    @include breakpoint($xs-media) {
      padding: rem(10);
    }

    .logo {
      margin-top: rem(10);

      @include breakpoint($xs-media) {
        margin: 0 0 rem(10);
      }
    }

    > .telephone-and-worktime {
      @include breakpoint($xs-media) {
        display: none;
      }

      .phones {
        position: relative;
        margin-bottom: rem(6);
        padding-left: rem(25);

        &:before {
          @extend .fa;
          @extend .fa-mobile;
          @include font-size(32);
          position: absolute;
          top: 0;
          left: 0;
          width: rem(25);
          text-align: center;
          color: #a2a0a0;
        }

        .telephone {
          @include font-size(19);
          line-height: 27px;
        }
      }

      .time {
        position: relative;
        margin-bottom: rem(6);
        padding-left: rem(25);

        &:before {
          @extend .fa;
          @extend .fa-clock-o;
          position: absolute;
          top: 3px;
          left: 0;
          width: rem(25);
          text-align: center;
          color: #a2a0a0;
        }
      }

      .callme {
        font-weight: bold;
        position: relative;
        padding-left: rem(25);
        color: black;

        &:before {
          @extend .fa;
          @extend .fa-refresh;
          position: absolute;
          top: 3px;
          left: 0;
          width: rem(25);
          text-align: center;
          color: $ui-site-color-6;
        }

        span {
          border-bottom: 1px dashed $ui-site-color-6;
        }
      }

      @include breakpoint($lg-media) {
        .phones {
          .telephone {
            @include font-size(24);
            line-height: 27px;
          }
        }
      }
    }

    .last {
      @include breakpoint($xs-media) {
        display: none;
      }

      .texts {
        text-align: center;
        color: #a2a0a0;
        @include font-size(17);
      }

      .bottom {
        .email-and-address {
          color: black;

          a {
            color: black;
          }

          .email {
            position: relative;
            margin-bottom: rem(6);
            padding-left: rem(25);

            &:before {
              @extend .fa;
              @extend .fa-envelope;
              position: absolute;
              top: 2px;
              left: 0;
              width: rem(25);
              text-align: center;
              color: #a2a0a0;
              @include font-size(16);
            }
          }

          .address {
            position: relative;
            padding-left: rem(25);

            &:before {
              @extend .fa;
              @extend .fa-map-marker;
              position: absolute;
              top: 2px;
              left: 0;
              width: rem(25);
              text-align: center;
              color: #a2a0a0;
              @include font-size(20);
            }
          }
        }

        .right {
          .calculate {
            .calculate-button {
              @extend .btn;
              @include button-variant(white, $ui-site-color-6, transparent);
              width: 100%;

              &:before {
                @extend .fa;
                @extend .fa-calculator;
                margin-right: rem(6);
              }
            }
          }
        }
      }
    }

    .mobile-only {
      .texts {
        text-align: center;
        color: #a2a0a0;
      }
    }
  }

  @include breakpoint($xs-media) {
    display: none;
  }
}

#navigation {
  position: relative;
  padding: 0;
  background: #828282;

  @include breakpoint($sm-media) {
    padding: 0 rem(20);
  }

  &.action {
    #superfish-1 {
      padding-right: rem(120);
    }
  }

  .action-link {
    font-weight: bold;
    line-height: 45px;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: rem(140);
    height: 100%;
    text-align: center;
    color: white;
    @include font-size(18);
    background: $ui-site-color-6;

    @include breakpoint($xs-media) {
      height: rem(40);
    }
  }

  .slicknav_menu {
    padding: 0 rem(10);
    color: $text-color;
    background: $ui-site-color-1;

    .slicknav_btn {
      display: inline-block;
      padding: rem(6);
      background: none;

      .slicknav_menutxt {
        font-family: $font-family;
        @include font-size(20, true);
        color: $text-color;

        &:before {
          @extend .fa;
          @extend .fa-bars;
          margin-right: rem(6);
        }
      }
    }

    .slicknav_nav {
      margin: 0;
      padding: 0;
      list-style: none;

      .slicknav_item {
        color: $text-color;
      }

      li {
        a {
          display: block;
          margin: 2px 5px;
          padding: 5px 10px;
          @include font-size(15, true);
          color: $text-color !important;
        }
      }
    }
  }

  @include breakpoint($xs-media) {
    #block-superfish-1 {
      display: none;
    }

    .mobile {
      display: block;

      #admin-menu-shortcuts-responsive {
        display: none;
      }
    }
  }

  @include breakpoint($sm-media) {
    #block-superfish-1 {
      display: block;
    }

    .mobile {
      display: none;
    }
  }
}

#main {
  position: relative;
  padding: rem(14) rem(20);
}

#sidebar-first,
.mobile-sidebar-content {
  .block {
    > .title {
      @include font-size(16, true);
      font-weight: bold;
      margin-top: 0;
      margin-bottom: rem(5);
      padding-bottom: rem(5);
      border-bottom: 1px solid $ui-site-color-1;
    }
  }

  .sidebar-title {
    margin-top: 0;
  }

  &.project-filter {
    padding: rem(10);
    border-radius: $border-radius-base;
    background: lighten($ui-site-color-6, 30%);
  }
}

#superfish-1 {
  @include breakpoint($xs-media) {
    display: none;
  }

  > li {
    border-right: 1px solid rgba(black, .1);

    &:last-child {
      border-right: none;
    }

    > a,
    > span {
      cursor: pointer;
      text-align: center;

      &:hover {
        color: black;
        background: $ui-site-color-1;
      }
    }

    &.active-trail,
    &.active {
      > a,
      > span {
        color: black;
        background: $ui-site-color-1;
      }
    }
  }

  li {
    position: relative;

    > ul {
      top: rem(45);
      border: 1px solid rgba(black, .1);
      background: $ui-site-color-1;

      li {
        border-bottom: 1px solid rgba(black, .1);

        &:last-child {
          border-bottom: none;
        }

        a {
          font-weight: normal;
          padding: rem(6) rem(10);
          text-transform: lowercase;
          color: #000000;

          &:first-letter {
            text-transform: uppercase;
          }

          &:hover {
            background: rgba(black, .1);
          }
        }
      }
    }

    a,
    span {
      font-weight: bold;
      display: block;
      padding: rem(10) rem(6);
      text-transform: uppercase;
      @include font-size(14, true);
      color: white;

      &.menuparent {
        &:before {
          display: block;
          content: '';
        }

        &:after {
          font-family: FontAwesome;
          position: relative;
          @include font-size(14, true);
          top: -2px;
          margin-left: rem($icon-padding);
          content: "\f078";
          color: $ui-site-color-6;
        }
      }

      @include breakpoint($md-media) {
        @include font-size(18, true);
      }
    }
  }
}

#page-title {
  margin-top: rem(10);
  margin-bottom: rem(15);
  color: $ui-site-color-5;
}

.article-teaser {
  margin-bottom: rem(20);
  padding-bottom: rem(20);
  border-bottom: 1px solid $ui-site-color-1;

  .promo {
    overflow: hidden;
    border-radius: 3px;

    @include breakpoint($xs-media) {
      margin-bottom: rem(10);

      img {
        width: 100%;
      }
    }
  }

  .content {
    .title {
      margin-top: 0;
      @extend h2;
    }
  }
}

.paragraph-text,
.paragraph-gallery,
.paragraph-single-image,
.paragraph-service-variants {
  margin-bottom: 15px;

  > .title {
    @extend h2;
  }
}

.paragraph-service-variants {
  > .entity {
    position: relative;
    width: rem(240);
    margin: rem(10);
    padding-bottom: rem(33);
    border: 1px solid $ui-site-color-1;
    box-shadow: 0 0 100px rgba(black, 0.05);

    .title {
      font-weight: bold;
      margin-bottom: rem(10);
      @include font-size(18, true);
      padding: rem(5) rem(10);
      text-align: center;
      color: $ui-site-color-5;
      background: $ui-site-color-1;
    }

    .price {
      font-weight: 100;
      @include font-size(20, true);
      margin-bottom: rem(10);
      text-align: center;
    }

    .content {
      padding: rem(10);
      padding-top: 0;
    }

    .order-button {
      @extend .btn;
      @include button-variant(white, $ui-site-color-6, $ui-site-color-6);
      position: absolute;
      bottom: 0;
      width: 100%;
      border-radius: 0;
    }
  }
}

.paragraph-gallery {

  .items {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(4, 1fr);

    @include breakpoint($xs-media) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .items a {
    overflow: hidden;
    margin-bottom: rem(10);
    border-radius: 3px;
  }
}

.paragraph-single-image {

  .image {
    display: table;
    margin: 0 auto;
  }
}

@keyframes read-more-dots {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.5);
  }
  66% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.news-teaser {
  margin-bottom: rem(20);
  padding-bottom: rem(20);
  border-bottom: 1px solid $ui-site-color-1;

  @include breakpoint($xs-media) {
    padding-bottom: rem(40);
  }

  .promo {
    overflow: hidden;
    border-radius: 3px;

    @include breakpoint($xs-media) {
      width: 100%;
      margin-bottom: rem(10);

      img {
        width: 100%;
      }
    }
  }

  .content {
    .title {
      margin-top: 0;
    }

    .footer {
      .item {
        display: block;
        float: left;
        margin-right: rem(15);
        padding: rem(4) rem(12);
        color: #1c69a9;
        border-radius: 2px;
        background: #eef4f9;

        @include breakpoint($xs-media) {
          margin-right: rem(6);
        }

        a {
          color: #1c69a9;
        }

        &:before {
          @extend .fa;
          margin-right: rem($icon-padding);
        }

        &.date:before {
          @extend .fa-calendar-o;
        }

        &.category:before {
          @extend .fa-folder-open-o;
        }

        &.read-more {
          .dots {
            .dot {
              display: inline-block;
              width: rem(4);
              height: rem(4);
              margin: rem(2) 0;
              margin-left: rem($icon-padding /2);
              border-radius: 50%;
              background: #1c69a9;

              &:nth-child(1) {
                animation: read-more-dots 1.5s linear 0s infinite;
              }

              &:nth-child(2) {
                animation: read-more-dots 1.5s linear .25s infinite;
              }

              &:nth-child(3) {
                animation: read-more-dots 1.5s linear .5s infinite;
              }
            }
          }
        }
      }
    }
  }
}

#footer {
  color: white;
  background: url('../../images/footer-bg.png') repeat;

  .blocks {
    padding: rem(20) rem(20);

    .block {
      > .title {
        @extend h2;
        //@include ui-top-border-line();
        text-transform: uppercase;
        @include font-size(18, true);
      }
    }

    .last {
      .views-field {
        margin-bottom: rem(10);

        .field-content {
          @include susy-clearfix;

          .promo {
            display: inline-block;
            overflow: hidden;
            width: 20%;
            margin-right: 1%;
            vertical-align: middle;
            border-radius: 3px;
          }

          .title {
            display: inline-block;
            width: 77%;
            vertical-align: middle;

            a {
              color: black;
            }
          }
        }
      }
    }
  }

  .copyright {
    padding: rem(20);
    background: #3a3a3a;
  }
}

.service-teaser {
  .service-container {
    position: relative;
    display: block;
    transition: all 1000ms;

    .image {
      width: 100%;
      transition: all 1000ms;
      opacity: 0;
    }

    .overlay {
      position: absolute;
      top: rem(60);
      width: 100%;
      padding: 0 rem(10);
      text-align: center;
      color: black;
      text-shadow: 1px 1px 1px transparent;

      .icon {
        display: table;
        margin: 0 auto;
      }

      .desc {
        transition: all 1000ms;
        opacity: 1;
        color: $text-color;
      }

      .title {
        margin-top: rem(10);
      }
    }

    &:hover {
      .image {
        transition: all 1000ms;
        opacity: 1;
      }

      .icon {
        -webkit-filter: grayscale(1) brightness(10);
        filter: grayscale(1) brightness(10);
      }

      .overlay {
        transition: all 1000ms;
        color: white;
        text-shadow: 1px 1px 1px rgba(black, .3);

        .desc {
          transition: all 1000ms;
          opacity: 0;
        }
      }
    }
  }

  @include breakpoint($xs-media) {
    height: 350px;
  }
}

.service-full {
  .icon-and-description {
    position: relative;
    min-height: rem(63);
    margin-bottom: rem(15);
    padding: rem(10);
    background: $ui-site-color-1;

    .icon {
      position: absolute;
      top: 50%;
      width: rem(40);
      transform: translateY(-50%);
    }

    .text {
      padding-left: rem(50);
    }
  }

  .variants {

  }

  .services {
    .title {
      margin-bottom: rem(15);
    }
  }

  .embedded-fences {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(3, 1fr);

    @include breakpoint($xs-media) {
      grid-template-columns: 1fr;
    }
  }

  .cases {
    > .title {
      @extend h2;
      margin-top: 15px;
      margin-bottom: rem(15);
      padding-top: 10px;
      padding-bottom: 10px;
      border-top: 2px solid #cccccc;
      border-bottom: 1px solid #e2e2e2;
    }
  }
}

#block-block-5 {
  .logo {
    width: rem(175);
    margin-bottom: rem(15);
  }

  .social {
    @include susy-clearfix;

    .item {
      display: inline-block;
      width: rem(50);
      height: rem(50);
      margin-right: rem(1);
      color: #828282;

      &:before {
        @extend .fa;
        @include font-size(28);
        line-height: 36px;
        width: 100%;
        text-align: center;
      }

      &:hover {
        color: $ui-site-color-6;
      }

      &.facebook {
        @extend .fa-facebook;
      }

      &.vk {
        @extend .fa-vk;
      }

      &.twitter {
        @extend .fa-twitter;
      }

      &.google {
        @extend .fa-google-plus;
      }

      &.youtube {
        @extend .fa-youtube;
      }
    }
  }
}

.project-teaser {
  position: relative;
  margin-bottom: rem(20);
  padding: rem(10);
  border: 1px solid #bdbdbd;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);

  &:before {
    position: absolute;
    right: rem(10);
    bottom: rem(10);
    display: block;
    width: 0;
    height: 0;
    content: '';
    border-width: 0 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent $ui-site-color-2 transparent;
  }

  > .header {
    position: relative;
    margin-bottom: rem(10);

    .price {
      font-weight: bold;
      position: absolute;
      bottom: 0;
      left: rem(10);
      padding: rem(3) rem(10);
      @include font-size(20, true);
      transform: translateY(50%);
      color: white;
      background: $ui-site-color-6;
    }

    .photo img {
      width: 100%;
    }
  }

  > .content {
    padding: rem(10);
    color: $ui-site-color-2;

    .title {
      font-weight: bold;
      @include font-size(18, true);
      margin: 0 0 rem(6) 0;

      a {
        color: $ui-site-color-2;
      }
    }

    .value {
      font-weight: bold;
    }

    .material {
      //font-weight: bold;
      margin-top: rem(6);

      .item {
        margin-right: rem(3);
        @include font-size(13);
        /*border: 1px solid #DEDEDE;
        border-radius: 3px;
        padding: rem(3) rem(6);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        background: white;
        display: inline-block;
        margin-top: rem(3);*/

        /*&:hover {
          background: $gray-lighter;
        }*/

        &:not(:last-child) {
          &:after {
            display: inline-block;
            content: ', ';
          }
        }
      }
    }
  }
}

.block-facetapi {
  .item-list {
    > ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        a {
          color: $ui-site-color-5;
        }
      }
    }
  }
}

.project-full {
  > .head {
    margin-bottom: rem(20);

    .left {
      @include breakpoint($xs-media) {
        margin-bottom: rem(20);
      }

      .photo-wrapper {
        .photos {
          overflow: hidden;
          margin-bottom: rem(10);

          .photo {
            width: rem(767);
            height: rem(400);
          }
        }

        .thumbs {
          position: relative;
          padding: 0 rem(30);

          .flickity-viewport {
            overflow: hidden;
          }

          .thumb {
            width: 168px;
            height: 140px;
            margin: 0 rem(5);
            cursor: move;
            cursor: -webkit-grab;
            cursor: grab;

            &.is-nav-selected {
              opacity: .8;
            }
          }

          .flickity-prev-next-button {
            position: absolute;
            z-index: 30;
            top: 50%;
            padding: 0;
            cursor: pointer;
            transform: translateY(-50%);
            color: black;
            border: none;
            background: none;

            svg {
              width: rem(24);
            }

            &.previous {
              left: 0;
            }

            &.next {
              right: 0;
            }

            .arrow {
              fill: $ui-site-color-4;
            }
          }
        }
      }
    }

    .right {
      padding: rem(10);

      .info {
        margin-bottom: rem(20);

        .item {
          @include font-size(16, true);
          position: relative;
          margin-bottom: rem(6);
          padding-left: rem(45);

          &:before {
            position: absolute;
            top: 50%;
            left: 0;
            display: inline-block;
            width: rem(35);
            height: rem(20);
            content: '';
            transform: translateY(-50%);
            text-align: center;
            color: $ui-site-color-6;
            background-repeat: no-repeat;
            background-position-x: 50%;
            background-size: contain;
          }

          .label-inline {
            color: darken($ui-site-color-2, 15%);
          }
        }

        .square {
          @include font-size(24, true);
          margin-bottom: rem(12);

          &:before {
            background-image: url('../../images/project/square.png');
          }
        }

        .material {
          &:before {
            background-image: url('../../images/project/material.png');
          }

          .material-item {
            display: inline-block;
            @include font-size(13);
            margin-top: rem(3);
            margin-right: rem(3);
            padding: rem(3) rem(6);
            cursor: pointer;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            -webkit-user-select: none;
            user-select: none;
            border: 1px solid #dedede;
            border-radius: 3px;
            background: white;
            -webkit-touch-callout: none;

            a {
              color: black;
            }

            &:hover {
              background: $gray-lighter;
            }

            /*&:not(:last-child) {
              &:after {
                content: ', ';
                display: inline-block;
              }
            }*/
          }
        }

        .foundation {
          &:before {
            background-image: url('../../images/project/foundation.png');
          }
        }

        .rooms {
          &:before {
            background-image: url('../../images/project/rooms.png');
          }
        }

        .price {
          @include font-size(24, true);
          margin-top: rem(6);

          &:before {
            background-image: url('../../images/project/price.png');
          }
        }

        .price-per-meter {
          font-size: 17px;
          padding-left: 45px;
        }
      }

      .order-button {
        @extend .btn;
        @include button-variant(black, $ui-site-color-6, transparent);
        position: relative;
        width: 100%;
        margin-bottom: rem(15);

        &:before {
          position: absolute;
          top: 6px;
          left: 12px;
          display: block;
          width: rem(60);
          height: rem(38);
          content: '';
          background: url('../../images/project/architect.png') no-repeat;
          background-size: contain;
        }

        .lines {
          padding-left: rem(34);
          text-align: left;

          .first {
            display: block;
          }

          .second {
            display: block;
          }
        }
      }

      .flexible-info {
        padding: rem(6) rem(12);
        color: white;
        border-radius: 3px;
        background: $ui-site-color-4;

        .first-line {
          position: relative;
          margin-bottom: rem(6);
          padding-left: rem(38);
          color: black;

          &:before {
            position: absolute;
            top: 5px;
            left: 0;
            display: block;
            width: rem(30);
            height: rem(38);
            content: '';
            background: url('../../images/project/pencil.png') no-repeat;
            background-size: contain;
          }

          > span {
            font-weight: bold;
            color: white;
          }
        }

        .second-line {
          font-weight: bold;
          text-align: center;
          text-transform: uppercase;
          color: white;
          @include font-size(15, true);
        }
      }

      .layout {
        margin-top: rem(28);

        .title {
          //@include ui-top-border-line();
          font-weight: bold;
          @include font-size(18, true);
          margin-bottom: rem(16);
          text-transform: uppercase;
          color: $ui-site-color-5;
        }

        .items {
          .item {
            margin-bottom: rem(10);

            img {
              border: 1px solid #d7d8d8;

              @include breakpoint($xs-media) {
                display: table;
                margin: 0 auto;
              }
            }

            .text {
              font-weight: bold;
              display: block;
              margin: rem(3) 0;
              text-align: center;
              text-shadow: 0 0 3px #f4f4f4;
            }
          }
        }
      }
    }
  }

  .complectation-and-description {
    width: calc(100% + 2.5rem);
    margin: 0 0 0 -1.25rem;
    padding: rem(30) rem(20);
    background: white;

    .complectation {
      > .title {
        @include font-size(22, true);
        font-weight: bold;
        margin-bottom: rem(10);
        //@include ui-top-border-line();
        text-transform: uppercase;
        color: $ui-site-color-5;
      }

      .equipment-tabs {
        .tabs {
          @include susy-clearfix;
          margin-bottom: rem(20);

          .tab {
            font-weight: bold;
            display: block;
            float: left;
            width: 32%;
            margin-right: 1%;
            margin-bottom: rem(6);
            padding: rem(10);
            cursor: pointer;
            text-align: center;
            @include font-size(18, true);
            color: $gray-light;
            border: 1px solid #d8d8d8;
            background: white;

            &:last-child {
              margin-right: 0;
            }

            &.active-element {
              color: $ui-site-color-6;
            }
          }
        }

        .content {
          .items {
            @include susy-clearfix;
            position: relative;
            display: none;

            &.active-element {
              display: block;
            }

            &.with-icon {
              padding-left: rem(90);

              .icon {
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }
        }
      }
    }

    .description {
      margin-top: rem(30);

      .desc-text {
        > .title {
          @include font-size(22, true);
          font-weight: bold;
          margin-bottom: rem(10);
          //@include ui-top-border-line();
          text-transform: uppercase;
          color: $ui-site-color-5;
        }
      }

      @include breakpoint($md-media) {
        margin-top: 0;
      }

      .request-consultation {
        margin: 10px 0;
        padding: 10px;
        background: $ui-site-color-1;

        .title {
          @include font-size(15, true);
          margin-top: 0;
        }
      }
    }
  }

  .similar-projects {
    width: calc(100% + 2.5rem);
    margin: 0 0 0 -1.25rem;
    padding: rem(10) rem(20);
    background: $ui-site-color-1;

    > .title {
      font-weight: bold;
      margin: rem(10) 0 rem(15);
      @include font-size(28, true);
      text-align: center;
      color: $ui-site-color-5;
    }
  }

  #block-block-6 {
    > .title {
      //@include ui-top-border-line();
    }
  }
}

.node-type-project {
  #main {
    padding-bottom: 0;
    //background: url('../../images/bordered-bg.png');
    background: #f3f3f3;
  }

  #footer {
    margin-top: 0;
  }
}

#block-views-brusmaster-slide-front {
  width: calc(100% + 2.5rem);
  margin-bottom: 0;
  margin-left: -1.25rem;
}

.brusmaster-slide-brusmaster-slide {
  .slide-wrapper {
    position: relative;
    height: rem(369);
    background-size: cover;

    .link {
      display: block;
      width: 100%;
      height: 100%;

      &:before {
        position: absolute;
        z-index: 10;
        top: 10px;
        left: 10px;
        display: block;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        content: '';
        border: 1px solid white;
      }
    }

    .content {
      font-weight: bold;
      position: absolute;
      bottom: rem(0);
      left: rem(0);
      width: rem(300);
      padding: rem(10) rem(10) rem(20) rem(40);
      color: white;
      background: rgba($ui-site-color-6, .75);

      .title {
        @include font-size(20, true);
        font-weight: bold;
        text-transform: uppercase;
      }

      .price {
        @include font-size(30, true);
      }
    }
  }
}

#block-block-6 {
  width: calc(100% + 2.5rem);
  margin-bottom: 0;
  margin-left: -1.25rem;
  padding: rem(20);
  background: lighten($ui-site-color-1, 5%);

  .row {
    margin-bottom: rem(10);

    &:last-child {
      margin-bottom: 0;
    }

    .label {
      font-weight: bold;
      line-height: 36px;
      @include font-size(17);
      text-transform: uppercase;
      color: $ui-site-color-2;
    }

    .items {
      .item {
        line-height: 36px;
        cursor: pointer;
        @include font-size(16, true);
        transition: all 500ms;
        text-align: center;
        color: $link-color;
        border-bottom: 1px solid rgba($link-color, .5);

        &:not(:first-child) {
          //margin-left: 10px;
        }

        &:hover {
          transition: all 500ms;
        }
      }
    }
  }
}


#block-block-10 {
  width: calc(100% + 2.5rem);
  margin-bottom: 0;
  margin-left: -1.25rem;
  padding: rem(50) rem(20);
  color: white;
  background: $ui-site-color-2;

  .items {
    .item {
      @include susy-clearfix;
      position: relative;
      margin-bottom: rem(60);

      @include breakpoint($xs-media) {
        &:last-child {
          margin-bottom: 0;
        }
      }

      @include breakpoint($sm-media) {
        &:nth-child(3),
        &:nth-child(4) {
          margin-bottom: 0;
        }
      }

      &:before {
        position: relative;
        top: -25px;
        display: block;
        width: 18%;
        height: 5px;
        content: '';
        background: $ui-site-color-6;
      }

      .icon {
        position: absolute;
        top: 50%;
        float: left;
        width: 18%;
        transform: translateY(-50%);
      }

      .number {
        line-height: 38px;
        float: left;
        width: 24%;
        @include font-size(30);
        margin-left: 20%;
        text-align: center;

        @include breakpoint($lg-media) {
          @include font-size(50);
        }
      }

      .text {
        float: left;
        width: 56%;
        @include font-size(14);

        @include breakpoint($lg-media) {
          @include font-size(16);
        }
      }
    }
  }

  @include breakpoint($md-media) {
    .items {
      .item {
        margin-bottom: 0;

        &:not(:last-child) {
          margin-right: rem(10) !important;
          border-right: 2px solid #727272;
        }
      }
    }
  }
}

#block-block-7,
#block-views-nodes-services-frontpage {
  > .title {
    margin-top: 0;
    margin-bottom: rem(20);
    @include font-size(30, true);
    text-align: center;
    color: $ui-site-color-2;
  }
}

.service-teaser-second {
  margin-bottom: rem(20);
  text-align: center;

  .title {
    @include font-size(16);
    line-height: 38px;
    display: block;
    margin-bottom: rem(10);

    a {
      color: $ui-site-color-6;
    }
  }

  .icon {
    display: table;
    margin: 0 auto;
  }
}

#block-views-nodes-popular {
  width: calc(100% + 2.5rem);
  margin-bottom: 0;
  margin-left: -1.25rem;
  padding: rem(20) rem(20) rem(10);
  background: transparent;

  > .title {
    margin-top: 0;
    margin-bottom: rem(20);
    @include font-size(30, true);
    text-align: center;
    color: $ui-site-color-2;
  }
}

#block-block-8 {
  width: calc(100% + 2.5rem);
  margin-bottom: 0;
  margin-left: -1.25rem;
  padding: rem(30) rem(20);
  background: white;

  .left {
    .item {
      position: relative;
      margin-bottom: rem(15);
      padding-left: rem(50);

      &:before {
        @extend .fa;
        @extend .fa-home;
        position: absolute;
        top: 3px;
        left: 0;
        width: 40px;
        text-align: center;
        color: $ui-site-color-6;
        @include font-size(40);
      }
    }
  }
}

.feed-icon {
  display: none;
}

#block-views-nodes-front-portfolio {
  width: calc(100% + 2.5rem);
  margin-bottom: 0;
  margin-left: -1.25rem;
  padding: rem(20);
  background: $ui-site-color-1;

  > .title {
    margin-top: 0;
    margin-bottom: rem(20);
    @include font-size(30, true);
    text-align: center;
    color: $ui-site-color-2;
  }
}

.front {
  #main {
    padding-top: 0;
    padding-bottom: 0;
    background: url('../../images/bordered-bg.png');
  }

  #footer {
    margin-top: 0;
  }

  #content {
    .block {
      > .title {
        //@include ui-top-border-line();
        @include font-size(20, true);
        margin-top: 0;
        margin-bottom: rem(20);
        text-align: left;
        text-transform: uppercase;
        color: $ui-site-color-5;
      }
    }
  }
}

.flickity-viewport {
  overflow: hidden;
}

.portfolio-teaser {
  position: relative;
  margin-bottom: rem(15);
  padding: 2px;
  border: 1px solid #e0e0e0;

  .link-wrapper {
    display: block;
    height: rem(200);
    background-size: cover;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      float: left;
      width: 100%;
      height: 100%;
      padding: rem(15);
      transition: all 500ms;
      color: white;
      background: transparent;

      .title {
        @include font-size(14, true);
        font-weight: bold;
        position: absolute;
        top: rem(185px);
        display: block;
        max-width: rem(248);
        padding: rem(6);
        transition: transform 500ms, background 250ms, text-shadow 500ms, top 500ms;
        transform: translateY(-100%);
        color: white;
        border-radius: 3px;
        background: #373028;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0);
      }

      .material {
        position: absolute;
        bottom: rem(15);
        width: rem(248);
        padding-top: rem(6);
        transition: all 500ms;
        opacity: 0;
        border-top: 1px solid transparent;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
      }
    }
  }

  &:hover {
    .link-wrapper {
      .overlay {
        transition: all 500ms;
        background: rgba($ui-site-color-6, .9);

        .title {
          top: rem(15);
          transition: transform 500ms, background 250ms, text-shadow 500ms, top 500ms;
          transform: translateY(0%);
          background: transparent;
          text-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
        }

        .material {
          transition: opacity 500ms 100ms, border-color 500ms 150ms;
          opacity: 1;
          border-color: white;
        }
      }
    }
  }
}

.view-nodes-front-portfolio {
  .content {
    position: relative;

    .portfolio-teaser {
      width: rem(273);
      margin-right: rem(20);
      margin-bottom: 0;
    }

    .flickity-prev-next-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border: none;
      background: none;

      &.previous {
        left: 0;
      }

      &.next {
        right: 0;
      }

      svg {
        width: rem(26);

        .arrow {
          fill: $ui-site-color-4;
        }
      }
    }
  }
}

.mfp-wrap {
  .mfp-close,
  .mfp-arrow {
    &:hover,
    &:focus {
      background: none;
    }
  }
}

.portfolio-full {
  > .content {
    width: 100%;

    > .left {
      .photo-wrapper {
        margin-bottom: rem(20);

        .photos {
          overflow: hidden;
          margin-bottom: rem(10);

          .photo {
            width: rem(865);
            height: rem(400);
          }
        }

        .thumbs {
          position: relative;
          padding: 0 rem(30);

          .flickity-viewport {
            overflow: hidden;
          }

          .thumb {
            width: 152px;
            height: 109px;
            margin: 0 rem(5);
            cursor: move;
            cursor: -webkit-grab;
            cursor: grab;

            &.is-nav-selected {
              opacity: .8;
            }
          }

          .flickity-prev-next-button {
            position: absolute;
            z-index: 30;
            top: 50%;
            padding: 0;
            cursor: pointer;
            transform: translateY(-50%);
            color: black;
            border: none;
            background: none;

            svg {
              width: rem(24);
            }

            &.previous {
              left: 0;
            }

            &.next {
              right: 0;
            }

            .arrow {
              fill: $ui-site-color-4;
            }
          }
        }
      }

      .description {
        > .title {
          //@include ui-top-border-line();
          margin-top: 0;
          text-transform: uppercase;
        }
      }
    }

    > .right {
      .info {
        margin-bottom: rem(30);

        > h3 {
          //@include ui-top-border-line();
          margin-top: 0;
          margin-bottom: rem(20);
          text-transform: uppercase;
        }

        .item {
          margin-bottom: rem(10);

          &:last-child {
            margin-bottom: 0;
          }

          .label-above {
            text-transform: uppercase;
            color: $gray-light;
          }
        }
      }

      .buttons {
        .button {
          display: block;
          margin-bottom: rem(15);
          @include font-size(17, true);
          padding: rem(6) 0;

          .text-wrap {
            position: relative;
            padding-left: rem(50);

            &:before {
              @extend .fa;
              line-height: 1;
              position: absolute;
              top: 50%;
              left: 0;
              display: block;
              width: rem(40);
              @include font-size(22);
              transform: translateY(-50%);
              text-align: center;
              border-right: 2px solid $ui-site-color-6;
            }
          }

          .first {
            font-weight: bold;
            @include font-size(17);
            line-height: 1.1;
            display: block;
          }

          .second {
            line-height: 1.1;
            @include font-size(14, true);
            display: block;
          }

          &.order {
            color: #7f7f7f;
            border: 2px solid #c6c6c6;

            .text-wrap:before {
              @extend .fa-file-text-o;
            }
          }

          &.callback {
            color: white;
            background: $ui-site-color-6;

            .text-wrap:before {
              @extend .fa-phone;
              border-color: white;
            }
          }
        }
      }
    }
  }

  .actual-projects,
  .other-portfolio {
    margin-bottom: 48px;
  }
}

.node-type-portfolio {
  #main {
    margin-bottom: unset;
    padding-bottom: unset;
  }

  #footer {
    margin-top: unset;
    padding-top: unset;
  }
}

.review-teaser {
  margin-bottom: rem(15);
  padding: rem(10);
  background: #f7f7f7;

  > .left {
    .promo {
      margin-bottom: rem(10);
      border: 2px solid $ui-site-color-4;
    }
  }

  > .right {
    .header {
      margin-bottom: rem(10);
      color: $gray;

      .name {
        float: left;

        &:before {
          @extend .fa;
          @extend .fa-user;
          margin-right: rem($icon-padding);
        }
      }
    }
  }
}

/*#modalBackdrop {
  background: rgba(black, .5) !important;
}

#modalContent {
  background: white;
  border-radius: 3px;
  overflow: hidden;
  max-width: rem(600);
  width: 100%;
  //position: fixed !important;
  //top: 50% !important;
  //transform: translateY(-50%);

  .ctools-modal-content {
    box-shadow: 0 15px 30px rgba(black, .45);
    margin: 0;
    border: none;
    width: 100%;

    .modal-header {
      background: #f2f2f2;
      color: #2e3436;
      padding: 10px;

      .modal-title {
        color: inherit;
        font-weight: bold;
        @include font-size(13.5, true);
      }

      .close {
        color: #888a85;
      }
    }
  }

  #modal-content {
    padding: 10px;

    input {
      display: inline-block;
    }

    .field-suffix {
      color: #9b9b9b;
    }
  }
}*/

.backdrop-entityform {
  opacity: 1 !important;
  background: rgba(black, 0.6) !important;
}

.modal-entityform {
  max-width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);

  .modal-wrapper {
    max-width: 700px;
    background: white;

    .modal-header {
      padding: 10px 15px;
      color: black;
      background: $ui-site-color-1;

      .modal-title {
        font-weight: bold;
      }

      .close {
        //@include icon('close');
        float: right;
        color: $ui-site-color-3;
      }
    }

    .modal-content {
      padding: 15px;

      .form-actions {
        width: calc(100% + 30px);
        padding: 15px;
        transform: translate(-15px, 15px);
        background: $ui-site-color-1;
      }
    }
  }
}

#block-brusmaster-project-wall-material {
  .items {
    .item {
      display: block;
      width: 100%;
      @include font-size(17, true);
      color: $ui-site-color-5;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

#block-menu-menu-price,
#block-menu-menu-area,
#block-menu-menu-prject-labels,
#block-menu-menu-size {
  .menu {
    margin: 0;
    padding: 0;
    list-style: none;

    li a {
      display: block;
      width: 100%;
      @include font-size(17, true);
      color: $ui-site-color-5;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}


.view-node-search-index-category {
  > .header {
    margin-bottom: rem(15);
  }
}

#contact-entityform-edit-form {
  .pre-instructions {
    .first {
      .phone, .email, .time, .address, .skype, .social {
        line-height: rem(25);

        &:before {
          @extend .fa;
          width: 20px;
          margin-right: rem(6px);
          text-align: center;
        }
      }

      .phone:before {
        @extend .fa-phone;
      }

      .email:before {
        @extend .fa-envelope-o;
      }

      .time:before {
        @extend .fa-clock-o;
      }

      .address:before {
        @extend .fa-globe;
      }

      .skype:before {
        @extend .fa-skype;
      }

      .social:before {
        @extend .fa-share-square-o;
      }

      .photo {
        float: right;
        width: rem(330);
        //margin-right: rem($icon-padding *2);
        border-radius: rem(3);
      }
    }

    .second {
      .scheme {
        .title {
          &:before {
            @extend .fa;
            @extend .fa-road;
            width: 20px;
            margin-right: rem(6px);
            text-align: center;
          }
        }

        > ymaps {
          max-width: 100% !important;
        }
      }

      .route {
        .info {
          &:before {
            @extend .fa;
            @extend .fa-file-text-o;
            width: 20px;
            margin-right: rem(6px);
            text-align: center;
          }
        }
      }
    }

    .third {
      .ppl {
        .info, .heads {
          &:before {
            @extend .fa;
            width: 20px;
            margin-right: rem(6px);
            text-align: center;
          }
        }

        .heads:before {
          @extend .fa-user;
        }

        .info:before {
          @extend .fa-road;
        }

        .about {
          @include font-size(12px);
        }

        .photo {
          @include breakpoint($sm-media) {
            width: 100px;
            margin-top: -31px;
          }
        }
      }

      .form-pane {
        .title {
          &:before {
            @extend .fa;
            @extend .fa-envelope-o;
            margin-right: rem($icon-padding);
          }
        }
      }
    }
  }

  @include breakpoint($xs-media) {
    .pre-instructions {
      .first {
        .photo {
          float: none;
          width: 100%;
          margin-bottom: rem(15);
        }
      }
    }
  }
}

.view-nodes-portfolio {
  .views-exposed-form {
    #edit-material-wrapper {
      position: relative;
      width: 100%;

      > label {
        position: absolute;
        display: block;
        float: left;
        width: 150px;
        @include breakpoint($xs-media) {
          position: relative;
          float: none;
          width: auto;
        }
      }

      > .views-widget {
        padding-left: 150px;

        @include breakpoint($xs-media) {
          padding-left: 0;
        }

        .form-item {
          display: inline-block;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          -webkit-user-select: none;
          user-select: none;
          -webkit-touch-callout: none;

          > input {
            display: none;
          }

          .option {
            padding: rem(3) rem(6);
            cursor: pointer;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            -webkit-user-select: none;
            user-select: none;
            border: 1px solid #dedede;
            border-radius: 3px;
            -webkit-touch-callout: none;

            &:hover {
              background: $gray-lighter;
            }
          }

          &.highlight {
            padding: 0;
            color: $text-color;
            background: none;

            .option {
              color: white;
              border-color: $ui-site-color-6;
              background: $ui-site-color-6;
              text-shadow: 0 1px 1px rgba(black, 0.1);
            }
          }
        }
      }
    }

    .form-submit {
      margin-top: 0;
    }

    .views-reset-button {
      position: absolute;
      bottom: 25px;
      float: right;
      padding: 0;

      @include breakpoint($xs-media) {
        position: relative;
        bottom: 0;
        float: none;
        margin-bottom: rem(15);
      }
    }

    .views-exposed-widgets {
      position: relative;
      margin: 0;

      .views-exposed-widget {
        padding: 0;

        @include breakpoint($xs-media) {
          float: none;
        }
      }
    }
  }
}

#block-block-9 {
  @include breakpoint($sm-media) {
    padding-left: rem(40);
  }

  .item {
    position: relative;
    margin-bottom: rem(20);
    padding-left: rem(40);

    &:before {
      @extend .fa;
      position: absolute;
      top: 50%;
      left: 0;
      width: rem(40);
      transform: translateY(-50%);
      text-align: center;
      color: $ui-site-color-6;
      @include font-size(28, true);
    }
  }

  .address {
    &:before {
      @extend .fa-map-marker;
    }
  }

  .telephones {
    &:before {
      @extend .fa-phone;
    }
  }

  .email {
    &:before {
      @extend .fa-envelope-o;
    }
  }
}

.view-nodes-footer-portfolio {
  .content {
    .item {
      margin-bottom: rem(10);
    }
  }
}

#block-block-11 {
  width: calc(100% + 2.5rem);
  margin-bottom: 0;
  margin-left: -1.25rem;
  padding: rem(20);
  background: $ui-site-color-1;
}

#block-block-12 {
  padding: rem(10) 0;

  .menu-and-slider {
    .menu {
      border: 1px solid #afafaf;

      .item {
        font-weight: bold;
        text-transform: uppercase;
        @include font-size(16, true);
        background: white;

        a {
          display: block;
          padding: rem(11.55) rem(15);
          transition: all 500ms;
          color: #a0a0a0;
          background: white;
          box-shadow: inset 0px 0px 0px 0px $ui-site-color-6;

          &:hover {
            transition: all 500ms;
            background: #ededed;
            box-shadow: inset 5px 0px 0px 0px $ui-site-color-6;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid #afafaf;
        }
      }
    }
  }
}

.mobile-only {
  display: block;

  @include breakpoint($sm-media) {
    display: none;
  }
}

#mobile-sidebar-button {
  position: absolute;
  top: rem(20);
  right: 0;
  padding: rem(10) rem(13);
  color: white;
  border-radius: 100px;
  background: $ui-site-color-6;
  @include font-size(24px);

  &:before {
    @extend .fa;
    @extend .fa-bars;
  }
}

.mobile-sidebar-active {
  #app {
    overflow: hidden;
  }
}

#mobile-sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100vw;
  height: calc(100% - 60px);
  background: white;
  overflow-y: auto;

  .mobile-sidebar-header {
    @include susy-clearfix;
    padding: rem(10);
    background: $ui-site-color-6;

    .close {
      float: right;
      color: black;
    }
  }

  .mobile-sidebar-content {
    overflow: scroll;
    height: 100%;
    padding: rem(10);
  }
}

.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: rem(26);
}

.view-node-search-index-projects,
.view-node-search-index-pane-projects-in-category {
  .filters {
    label {
      display: none;
    }

    .bef-select-as-links {
      .form-item {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 0;
        padding-bottom: 0;

        #edit-sort-field-area-asc {
          @include icon('caret-down');
        }

        #edit-sort-field-area-desc {
          @include icon('caret-up');
        }

        #edit-sort-field-price-asc {
          @include icon('caret-down');
        }

        #edit-sort-field-price-desc {
          @include icon('caret-up');
        }

        a {
          color: #333333;
        }

        &.selected {
          font-weight: bold;
        }
      }
    }
  }
}

@for $i from 1 through 40 {
  .view-project-page-with-fitlers-page-#{$i} {
    .header {
      margin-bottom: rem(15);
    }

    > .content {
      display: grid;
      grid-gap: 24px;
      grid-template-columns: 1fr;

      @include breakpoint($sm-media) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include breakpoint($md-media) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    .filters {
      label {
        display: none;
      }

      .bef-select-as-links {
        .form-item {
          display: inline-block;
          margin-bottom: 0;
          padding-bottom: 0;
          margin-right: 10px;

          #edit-sort-field-area-value-asc {
            @include icon('caret-down');
          }

          #edit-sort-field-area-value-desc {
            @include icon('caret-up');
          }

          #edit-sort-field-price-value-asc {
            @include icon('caret-down');
          }

          #edit-sort-field-price-value-desc {
            @include icon('caret-up');
          }

          a {
            color: #333333;
          }

          &.selected {
            font-weight: bold;
          }
        }
      }
    }
  }
}

#block-views-nodes-services-frontpage {
  width: calc(100% + 2.5rem);
  margin-bottom: 0;
  margin-left: -1.25rem;
  padding: rem(20);
  background: lighten($ui-site-color-1, 5%);
}

.partner-teaser {
  overflow: hidden;
  border: 1px solid darken($ui-site-color-1, 20%);
  border-radius: 4px;
  background: $ui-site-color-1;

  .logo {
    padding: 10px;
  }

  .info {
    padding: 10px;

    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .address {
      @include icon('map-marker');
    }

    .website {
      @include icon('globe');
    }

    .address,
    .website {
      &:before {
        width: 16px;
        text-align: center;
      }
    }
  }
}

.article-teaser-second {
  margin-bottom: 15px;

  .promo {
    margin-bottom: 15px;
    transition: transform 500ms;
    transform: scale(1);

    &:hover {
      transition: transform 500ms;
      transform: scale(1.1);
    }
  }

  .title {
    @extend h4;
    margin-top: 0;
    margin-bottom: 15px;
  }
}

.fence-full {
  > .header {
    @include susy-clearfix;
    margin-bottom: 20px;

    .left {
      @include span(6);

      .photos {
        position: relative;

        .photo {
          width: 100%;
          height: 260px;
        }

        .flickity-viewport {
          overflow: hidden;
        }

        .thumb {
          width: 168px;
          height: 140px;
          margin: 0 rem(5);
          cursor: move;
          cursor: -webkit-grab;
          cursor: grab;

          &.is-nav-selected {
            opacity: .8;
          }
        }

        .flickity-prev-next-button {
          position: absolute;
          z-index: 30;
          top: 50%;
          padding: 0;
          cursor: pointer;
          transform: translateY(-50%);
          color: black;
          border: none;
          background: none;

          svg {
            width: rem(24);
          }

          &.previous {
            left: 0;
          }

          &.next {
            right: 0;
          }

          .arrow {
            fill: $ui-site-color-4;
          }
        }
      }
    }

    .right {
      @include span(6 last);

      .params {
        .param {
          @include susy-clearfix;
          padding: 4px;

          &:nth-child(2n) {
            background: #f5f5f5;
          }

          .label {
            @include span(6);
          }

          .value {
            @include span(6 last);
          }
        }
      }

      .price {
        font-size: 24px;
        font-weight: bold;
        margin: 10px 0;
        text-align: center;
        color: $ui-site-color-6;
      }
    }
  }
}

.view-nodes-fences {
  .content {
    @include susy-clearfix;

    .fence-teaser {
      @include gallery(4);
    }
  }
}

.fence-teaser {
  position: relative;
  margin-bottom: rem(20);
  padding: rem(10);
  border: 1px solid #bdbdbd;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);

  &:before {
    position: absolute;
    right: rem(10);
    bottom: rem(10);
    display: block;
    width: 0;
    height: 0;
    content: '';
    border-width: 0 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent $ui-site-color-2 transparent;
  }

  > .header {
    position: relative;
    margin-bottom: rem(10);
    border-bottom: 4px solid $ui-site-color-6;

    .price {
      font-weight: bold;
      position: absolute;
      bottom: 0;
      left: rem(10);
      padding: rem(3) rem(10);
      @include font-size(20, true);
      transform: translateY(50%);
      color: white;
      background: $ui-site-color-6;
    }
  }

  .content {
    padding: rem(10);
    color: $ui-site-color-2;

    .title {
      font-weight: bold;
      @include font-size(18, true);
      margin: 0 0 rem(6) 0;

      a {
        color: $ui-site-color-2;
      }
    }
  }
}

.view-nodes-popular {
  .footer {
    .all-projects-button {
      @include button-variant(white, $ui-site-color-6, transparent);
      font-weight: bold;
      display: table;
      margin: 0 auto;
      padding: 10px;
    }
  }
}

.article-full {
  .related-projects {

  }

  .related-projects-items {
    lost-flex-container: row;
  }

  .related-projects-item {
    lost-waffle: 4/12 3;
  }

  @include breakpoint($xs-media) {
    .related-projects-item {
      lost-waffle: 12/12 1;
    }
  }
}

.video-replacer {
  @extend .fa;
  @extend .fa-youtube-play;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  cursor: pointer;

  &:before {
    font-size: 70px;
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    margin: 0;
    transition: color 500ms;
    transform: translate(-50%, -50%);
    color: rgba(black, .85);
  }

  &.replaced {
    &:before {
      display: none;
    }
  }

  &:hover {
    &:before {
      transition: color 500ms;
      color: #e52d27;
    }
  }

  .preview {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .image {
      position: absolute;
      z-index: -1;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
    }
  }
}

.slicknav_row {
  display: flex;
  justify-content: space-between;
}

.apply-for-discount {
  padding: 32px 16px;
  background-color: #eeeeee;
}

.apply-for-discount__title {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 24px;
  text-align: center;
}

.apply-for-discount__container {
  display: flex;
  flex-flow: wrap;
  max-width: 600px;
  margin: 0 auto;
}

#apply-for-discount-entityform-edit-form {
}

#apply-for-discount-entityform-edit-form .pre-instructions {
  display: none;
}

#apply-for-discount-entityform-edit-form > div {
  display: grid;
  justify-content: center;
  grid-gap: 24px;
  grid-template-columns: 1fr 125px;
}

#apply-for-discount-entityform-edit-form .field-name-field-telephone {
  min-width: 0;
}

#apply-for-discount-entityform-edit-form .form-item {
  margin-bottom: 0;
  padding: unset;
}

#apply-for-discount-entityform-edit-form .form-actions {
  align-self: flex-end;
}

.header-mobile {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: none;
  justify-content: flex-end;
  width: 100%;
  height: 60px;
  background-color: #f0f1e7;

  @include breakpoint($xs-media) {
    display: flex;
  }
}

.header-mobile__site-name {
  font-size: 18px;
  font-weight: bold;
  line-height: 60px;
  padding: 0 16px;
  color: black;
  margin-right: auto;
}

.header-mobile__phone {
  width: 60px;
  text-align: center;
  color: black;

  &:before {
    @extend .fa;
    @extend .fa-phone;
    font-size: 24px;
    line-height: 60px;
  }
}

.header-mobile__toggle {
  width: 60px;
  text-align: center;

  &:before {
    @extend .fa;
    @extend .fa-bars;
    font-size: 24px;
    line-height: 60px;
  }
}

.header-mobile__toggle--active {
  &:before {
    content: ""
  }
}

.mobile-navigation {
  position: fixed;
  z-index: 100;
  top: 60px;
  left: 0;
  display: none;
  width: 100%;
  height: calc(100% - 60px);
  background-color: #ffffff;
}

.mobile-navigation--active {
  @include breakpoint($xs-media) {
    display: flex;
  }
}

.mobile-menu {
  width: 100%;
  margin: 24px 0;
  padding: unset;
  list-style: none;
  border-top: 1px solid #eeeeee;
}

.mobile-menu__item {
  border-bottom: 1px solid #eeeeee;
}

.mobile-menu__link {
  font-size: 18px;
  display: block;
  padding: 8px 16px;
  color: black;
}

.mobile-menu__submenu {
  width: 100%;
  margin: 0 0 0 24px;
  padding: unset;
  list-style: none;
}

.mobile-menu__submenu-link {
  font-size: 16px;
  display: block;
  padding: 8px 16px;
  color: black;
}

