/**
 * @file
 *
 * MD media query styles.
 */

@include breakpoint($md-media) {
  $site-width: 992px;
  #app {
    @include container($site-width);
  }

  #content {
    @include span(9);
    .no-sidebars & {
      @include full();
    }

    .sidebar-second & {
      @include span(9 last);
    }
  }
  #sidebar-first {
    @include span(3 last);
  }
  #sidebar-second {
    @include span(3);
  }

  #header {
    .main {
      @include susy-clearfix;

      .logo {
        @include span(4);
      }

      .telephone-and-worktime {
        @include span(5 );
        padding-left: 100px;
      }

      .last {
        @include span(3 last);

        .bottom {
          .email-and-address {
          }

          .right {
          }
        }
      }
    }
  }

  #block-block-10 {
    .items {
      @include flexbox;
      @include flex-flow();
      @include justify-content();

      .item {
        @include flex(1, 1, auto);
        @include susy-clearfix;
        margin: 0 !important;
      }
    }
  }

  .view-nodes-services {
    .content {
      @include susy-clearfix;

      .service-teaser {
        @include gallery(4);
      }
    }
  }

  .project-full {
    > .head {
      @include susy-clearfix;

      .left {
        @include span(8);
      }

      .right {
        @include span(4 last);

        .layout {
          .items {
            @include susy-clearfix;

            .item {
              @include gallery(6);
            }
          }
        }
      }
    }

    .complectation-and-description {
      @include susy-clearfix;

      .complectation {
        @include span(8);
      }

      .description {
        @include span(4 last);
      }
    }

    .similar-projects {
      .items {
        @include susy-clearfix;

        .project-teaser {
          @include gallery(3);
        }
      }
    }
  }

  .portfolio-full {
    > .content {
      lost-flex-container: row;

      > .left {
        lost-column: 9/12;
      }

      > .right {
        lost-column: 3/12;
      }
    }
  }

  .service-full {
    .cases {
      .items {
        @include susy-clearfix;

        .article-teaser-second {
          @include gallery(4);
        }
      }
    }
  }
}
