/**
 * @file
 * Styles for system messages.
 */
.messages {
  position: relative;
  min-height: 1em;
  margin: 0 0 rem(20);
  background: #efefef;
  padding: 1em 1.5em;
  line-height: 1.3;
  color: rgba(0, 0, 0, .8);
  border-radius: .2857rem;
  border: 1px solid transparent;

  &.error {
    border-color: $message-error-border;
    background-color: $message-error-bg;
    color: $message-error-color;
  }

  &.status {
    border-color: $message-status-border;
    background-color: $message-status-bg;
    color: $message-status-color;
  }

  &.warning {
    border-color: $message-warning-border;
    background-color: $message-warning-bg;
    color: $message-warning-color;
  }
}
