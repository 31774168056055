/**
 * @file
 *
 * LG media query styles.
 */

@include breakpoint($lg-media) {
  $site-width: 1200px;

  #app {
    @include container($site-width);
  }
}
