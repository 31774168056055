/**
 * @file
 * Font, line-height for body text, headings, and more.
 */

// Font family.
$font-family-sans-serif: 'Clear Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:      Georgia, "Times New Roman", Times, serif;
$font-family-monospace:  Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family:            $font-family-sans-serif;

// Font sizes.
$font-size-base:  16px;
$font-size-large: ceil($font-size-base * 1.25);
$font-size-small: ceil($font-size-base * 0.85);
$font-size-h1:    31px;
$font-size-h2:    floor($font-size-base * 1.75);
$font-size-h3:    ceil($font-size-base * 1.5);
$font-size-h4:    ceil($font-size-base * 1.25);
$font-size-h5:    $font-size-base;
$font-size-h6:    ceil($font-size-base * 0.85);

// Line height.
// Unit-less cuz used in buttons, inputs, etc.
$line-height-base:     1.428571429;
$line-height-computed: floor($line-height-base * $font-size-base);
// Used in compontents.
$line-height-large: 1.3333333;
$line-height-small: 1.5;

// Headers default settings.
$headings-font-family:    inherit !default;
$headings-font-weight:    500 !default;
$headings-line-height:    1.1 !default;
$headings-color:          inherit !default;

// Blockqoutes.
$blockquote-font-size:    ($font-size-base * 1.25) !default;
$blockquote-border-color: $gray-lighter !default;

// <hr> tag.
$hr-border-color: $gray-lighter !default;
