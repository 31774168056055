/**
 * @file
 * Button styles.
 */

/**
 * Default styles and resets for all buttons used as base for all others.
 */
input[type="submit"],
input[type="button"],
input[type="reset"],
button,
.btn {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  font-weight: $btn-font-weight;
  padding: $padding-base-vertical $padding-base-horizontal;
  line-height: $line-height-base;
  color: $btn-default-color;
  background: $btn-default-bg;
  border: 1px solid $btn-default-border;
  box-sizing: border-box;
  border-radius: $btn-border-radius;
  text-align: center;
}

/**
 * Default button style.
 */
input[type="button"],
button,
.btn {
  @extend .btn;
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}

/**
 * Submit and primary button style.
 */
input[type="submit"],
.btn-primary {
  @extend .btn;
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

/**
 * Warning and preview buttons style.
 */
.btn-warning,
#edit-preview {
  @extend .btn;
  @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

/**
 * Danger and delete button style.
 */
.btn-danger,
#edit-delete {
  @extend .btn;
  @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

/**
 * Success button style.
 */
.btn-success {
  @extend .btn;
  @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

/**
 * Info button style.
 */
.btn-info {
  @extend .btn;
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}
